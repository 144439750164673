@font-face {
  font-family: 'MuseoSans';
  src: url('../fonts/MuseoSans_500.otf');
  font-weight: normal;
  font-style: normal;
}

.correction {
  width: 400px;

  &-container {
    overflow: auto;
    width: 640px;
    border-left: 2px solid #f1f3ff;
    height: 100vh;
    padding: 50px;
    position: fixed;
    left: 50%;
    box-sizing: border-box;
    transform: translateZ(0);
  }

  &-buttons {
    padding-bottom: 90px;
    white-space: nowrap;

    & > button {
      border-radius: 8px;
      background-color: #e6ebff;
      color: #334cff;
      box-shadow: none;
      padding: 6px 20px;
      font-family: MuseoSans;
      text-transform: capitalize;
      margin-right: 15px;

      &:hover {
        background-color: #e6ebff;
        color: #334cff;
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
      }

      &.active {
        background-color: #334cff;
        color: #e6ebff;
      }

      & > span > img {
        width: 0.7rem;
        margin-right: 8px;

        &.docs {
          width: 0.85rem;
          margin-right: 8px;
        }
      }
    }
  }

  &-empty {
    color: #bdbdbd;

    & > img {
      width: 1.1rem;
      margin-right: 5px;
    }
  }

  &-nav {
    padding: 0;
    list-style: none;
    display: flex;
    width: 100%;
    flex: 1 0 0;
    justify-content: flex-start;
    margin: 0;
    height: 30px;
    padding-bottom: 40px;

    & li {
      cursor: pointer;
      width: 18%;
      text-align: center;
      border-bottom: 2px solid #d9e1ff;
      color: #d9e1ff;
      margin-right: 3%;
      transition: border-bottom-color 0.3s ease, color 0.3s ease;

      &.active {
        width: 18%;
        text-align: center;
        border-bottom: 2px solid #334cff;
        color: #334cff;
      }
    }
  }

  &-word {
    background-color: white;
    color: #324bfc;
    padding: 5px 2px;
    border-radius: 5px;
    cursor: pointer;
    transition: color 0.3s ease, background-color 0.3s ease;

    &:hover {
      background-color: #324bfc;
      color: white;
    }
  }

  &-removed-word {
    position: absolute;
    top: -5px;
    left: 0;
    white-space: nowrap;
    background-color: #cccccc;
    color: transparent;
    display: inline-block;
    line-height: 18px;
    padding: 5px 2px;
    border-radius: 5px;
    cursor: pointer;
    transition: color 0.3s ease, background-color 0.3s ease, width 0.1s ease;

    &.open {
      background-color: #fc3232;
      width: unset;
      color: white;
    }
  }

  &-removed-marker {
    position: relative;
    display: inline-block;
    width: 10px;
    vertical-align: text-top;
  }

  &-spinner {
    display: inline-block;
    width: 35px;
    height: 35px;
    border: 3px solid #334cff;
    border-radius: 50%;
    border-top-color: #e6ebff;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    position: fixed;
    left: calc(50% - 40px);
    top: 200px;
  }

  &-block {
    color: #bdbdbd;
    word-spacing: 0.5px;

    &-line {
      display: flex;
      height: 1px;
      width: 100%;
      background: #d9e0ff;

      margin-top: 50px;
      margin-bottom: 40px;
    }

    &-apply {
      color: #334cff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 16px;
      padding-right: 16px;

      & .material-icons {
        font-size: 30px;
      }

      & :not(.material-icons) {
        font-size: 16px;
      }
    }
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@media (min-width: 1440px) {
  .correction-buttons > button .text-shot {
    display: none;
  }

  .correction-buttons > button .text-long {
    display: block;
  }
}

@media (min-width: 1140px) and (max-width: 1439px) {
  .correction-buttons > button .text-shot {
    display: none;
  }

  .correction-buttons > button .text-long {
    display: block;
  }
}

@media (min-width: 640px) and (max-width: 1280px) {
  .correction-container {
    width: calc(50% - 100px);
  }
}

@media (min-width: 769px) and (max-width: 1139px) {
  .correction {
    width: 330px;
    font-size: 13px !important;
  }

  .correction-container {
    width: 50%;
  }

  .correction-buttons {
    display: flex;
    flex-direction: row;
  }

  .correction-buttons > button .text-shot {
    display: block;
  }

  .correction-buttons > button .text-long {
    display: none;
  }
}

.corrections-count {
  position: fixed;
  bottom: 20px;
  width: 80%;
}

.corrections-bar-wrapper {
  width: 100%;
  height: 3px;
  border-radius: 16px;
  background: #d9e1ff;
  position: relative;
}

.corrections-bar-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #334cff;
  border-radius: 16px;
}

.corrections-count-text {
  padding-top: 0.5rem;
  color: #a3b8e5;
}

.corrections-used {
  background: #fff929;
  color: #334cff;
  padding: 4px 4px;
}
