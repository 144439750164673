.settings {
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-family: MuseoSans;
  font-size: 1rem;
  color: #a8aaaf;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;

  @media (max-width: 575px) {
    position: absolute;
    bottom: unset;
  }

  @media (min-width: 768px) {
    max-height: 100vh;
    max-width: 100vw;
    padding-top: 15vh;
    align-items: flex-start;
  }

  .MuiTypography-body1 {
    font-family: MuseoSans;
  }

  .card {
    position: relative;
    background: #fff;
    padding: 2rem 4rem;
    margin: auto auto;
    border-radius: 0.5rem;
    box-sizing: border-box;

    @media (max-width: 767px) {
      padding: 1.5rem 1rem;
    }

    @media (min-width: 376px) {
      margin: 1rem;
      max-width: 600px;
    }

    @media (min-width: 576px) {
      min-width: 80vw;
    }

    @media (min-width: 992px) {
      margin: 1rem;
      min-width: 610px;
    }


    .back-button {
      display: flex;
      flex: 1;
      justify-content: flex-start;
      align-items: center;
      font-size: 1rem;
      cursor: pointer;

      @media (max-width: 991px) {
        font-size: 0.875rem;
      }

      @media (min-width: 992px) {
        display: none;
      }
    }

    .back-arrow {
      height: 20px;
      width: 20px;
    }

    .back-text {
      margin-left: 0.8rem;
      color: #334CFF;

      @media (max-width: 599px) {
        margin-left: 0.4rem;
        margin-right: 0.4rem;
      }

      @media (min-width: 768px) {
        font-size: 1rem;
      }
    }

    .choices {
      display: flex;
      padding: 0;
      margin: 2rem 0 1rem 0;

      @media (max-width: 991px) {
        flex-direction: column;
        margin: 2rem 0 2.0rem 0;
      }

      @media (max-width: 767px) {
        margin: 2rem 0 1.5rem 0;
      }

      @media (min-width: 991px) {
        margin: 2rem 0 1.5rem 0;
      }

      .choice {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 2px solid #c7cce0;
        border-radius: 8px;
        padding: 2rem;
        text-align: center;
        line-height: 1.5rem;
        font-weight: 500;
        cursor: pointer;
        user-select: none;

        &:hover {
          border: 2px solid #334cff;
        }

        @media (max-width: 991px) {
          padding: 2rem 2rem 2rem 2rem;
          flex-direction: row;
          margin: 0.5rem 0;
        }

        @media (max-width: 767px) {
          padding: 2rem 0.5rem 2rem 1.25rem;
          flex-direction: row;
          margin: 0.5rem 0;
        }

        @media (max-width: 575px) {
          padding: 1.25rem 0.8rem 1.25rem 1.25rem;
          flex-direction: row;
          margin: 0.5rem 0;
          box-sizing: border-box;
        }

        @media (min-width: 992px) {
          margin: 0 0.5rem;
          padding: 2rem 0.8rem;
        }

        svg {
          display: block;
          color: #a8aaaf;

          @media (max-width: 767px) {
            width: 54px;
            padding: 0;
            margin: -0.8rem 1.8rem 0 0rem;
          }

          @media (max-width: 991px) {
            width: 54px;
            padding: 0;
            margin: -0.8rem 2.5rem 0 0.5rem;
          }

          @media (min-width: 992px) {
            width: 54px;
            margin: 0 0 2rem 0;
          }
        }

        span {
          flex: 1;
          display: block;
          font-size: 0.9rem;
          line-height: 1.2rem;
          text-align: left;

          @media (min-width: 992px) {
            text-align: center;
          }
        }

        &.active {
          border: 2px solid #334cff;
          color: #000000;

          svg {
            color: #334cff;
          }
        }
      }
    }

    .info {
      margin: 1.5rem 0 0.8rem;
      text-align: center;
      font-size: 0.85rem;

      @media (min-width: 992px) {
        margin-top: 2rem;
      }

      p {
        margin: auto;
        line-height: 1.5rem;
      }
    }

    .action {
      text-align: center;

      button {
        border-radius: 8px;
        background-color: #e6ebff;
        box-shadow: none;
        padding: 0.60rem 3rem;
        font-family: MuseoSans;
        font-size: 1rem;
        text-transform: capitalize;
        line-height: normal;

        &:not(:disabled) {
          background-color: #334cff;
          color: white;

          &:hover {
            box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
          }

          &:active {
            background-color: #334cff;
            color: #e6ebff;
          }
        }
      }
    }

    @media (max-width: 991px) {
      .language-grid-item {
        margin-left: 2rem;
      }
    }
  }

  .header-tabs {
    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
      margin: 0px 12px;
      padding: 0px 1rem 0.625rem 1rem;
      font-weight: normal;
      font-size: 1rem;
      border-bottom: 2px solid transparent;
      color: #334cff;
      cursor: pointer;
      white-space: nowrap;
      text-align: center;

      &.selected {
        border-bottom-color: #334cff;
        cursor: auto;
      }

      @media (max-width: 767px) {
        padding: 0px 3px;

        .desktop-only {
          display: none;
        }
      }

      @media (min-width: 768px) {
        .mobile-only {
          display: none;
        }
      }

      @media (max-width: 575px) {
        font-size: 0.875rem;
      }
    }
  }

  .switch-label {
    margin: 0px;
    align-items: flex-start;
    margin-left: 30px;

    p {
      font-size: 0.9rem;
      margin: 0px;
    }
  }

  @media (max-width: 991px) {
    .switch-label {
    }

    .big-switch {
      margin-left: 32px;
    }
  }

  .switch-control {
    align-items: flex-start;
    margin-right: 0px;

    @media (max-width: 767px) {
      margin-bottom: 32px;
    }
  }

  .language-menu-btn {
    font-family: MuseoSans;
    text-transform: none;
    background: #E6EBFF;
    border-radius: 8px;
    padding-right: 24px;
    white-space: nowrap;

    img {
      width: 7px;
      height: 10px;
      margin-left: 12px;
      margin-right: 12px;
    }

    span {
      font-size : 14px;
      color : #334CFF;
    }
  }

  .divider {
    height: 1px; 
    width: 100%;
    background: #C7CCE0;
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .close-btn {
    position: absolute;
    top: 1rem;
    right: 1.25rem;
    display: none;

    @media (min-width: 992px) {
      display: block;
    }
  }

  .close-icon {
    width: 1.25rem;
    height: 1.25rem;
  }

  @media (max-width: 767px) {
    .close-btn {
      top: 0.5rem;
      right: 0.5rem;
    }

    .close-icon {
      width: 1rem;
      height: 1rem;
    }
  }

  .settings-grid {
    @media (min-width: 992px) {
      align-items: flex-start;
    }
  }

  .language-settings, .language-settings p {
    line-height: normal;
    font-size: 0.9rem;
    margin: 0px 0px 6px 0px;
  }
}
