@font-face {
  font-family: 'MuseoSans';
  src: url('../fonts/MuseoSans_500.otf');
  font-weight: normal;
  font-style: normal;
}

@media (max-width: 768px) {
  .correction-container {
    overflow: auto;
    background: #f3f6ff;
    width: 100%;
    height: auto;
    position: fixed;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    min-height: 80px;
  }

  .correction {
    font-size: 16px !important;
    width: 100%;
  }

  .correction-empty {
    color: #bdbdbd;
  }

  .correction-empty > img {
    width: 1.1rem;
    margin-right: 5px;
  }

  .carousel .slide {
    background: none !important;
    text-align: left !important;
  }

  .carousel ul.control-dots {
    margin: 0;
    padding: 0;
  }

  .carousel .control-dots li.dot {
    transition: opacity 0.25s ease-in;
    opacity: 0.3;
    box-shadow: none;
    background: #324bfc;
    width: 20px;
    height: 3px;
    border-radius: 3px;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;
  }

  .correction-word {
    background-color: #f3f6ff;
    color: #324bfc;
    padding: 5px 2px;
    border-radius: 5px;
    cursor: pointer;
    transition: color 0.3s ease, background-color 0.3s ease;
  }

  .correction-word:hover {
    background-color: #324bfc;
    color: white;
  }

  .correction-removed-word {
    position: absolute;
    top: -5px;
    left: 0;
    white-space: nowrap;
    background-color: #cccccc;
    color: transparent;
    display: inline-block;
    line-height: 18px;
    padding: 5px 2px;
    border-radius: 5px;
    cursor: pointer;
    transition: color 0.3s ease, background-color 0.3s ease, width 0.1s ease;

    &.open {
      background-color: #fc3232;
      width: unset;
      color: white;
    }
  }

  .correction-removed-marker {
    position: relative;
    display: inline-block;
    width: 10px;
    vertical-align: text-top;
  }

  .correction-spinner {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #334cff;
    border-radius: 50%;
    border-top-color: #e6ebff;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    position: fixed;
    left: calc(50% - 20px);
    top: 30px;
  }

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  .correction-block {
    color: #c1c8ea;
    word-spacing: 0.5px;
    margin-bottom: 40px;
    line-height: 30px !important;
    max-height: calc(30px * 6);
    overflow: scroll;
  }

  .correction-block-apply .material-icons {
    font-size: 23px;
    padding-left: 0px;
    padding-right: 8px;
  }

  .correction-block-apply {
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #334cff;
    height: 36px;
    border-radius: 7px;
    font-size: 14px;
    margin-bottom: 35px;
    white-space: nowrap;
  }
}

@media (min-width: 321px) and (max-width: 576px) {
  .correction-container {
    padding: 30px 50px 20px 50px;
    min-height: 80px;
  }

  .correction {
    font-size: 15px !important;
    width: 100%;
  }

  .correction-block {
    margin-bottom: 40px;
    line-height: 27px !important;
    max-height: calc(27px * 4);
    overflow: scroll;
  }

  .correction-block-apply {
    font-weight: 500;
    margin-bottom: 25px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
