.nav {
  display: flex;
  flex-direction: column;
  background: #324bfc;
  height: 100vh;
  text-align: left;
  position: absolute;
  top: 0;
  transition: left 0.75s ease;
  width: 100vw;
  z-index: 1;
  left: -786px;
  padding: 100px;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}

.nav.open {
  left: 0;
}

.nav.open a {
  display: block;
}

.nav a {
  display: none;
  font-size: 2rem;
  text-transform: uppercase;
  padding: 2rem 0;
  font-weight: bold;
  letter-spacing: 0.5rem;
  color: white;
  text-decoration: none;
  transition: color 0.3s linear;
}

.button {
  color: white;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 50px;
  white-space: nowrap;
}

.button img {
  width: 1.3rem;
  margin-right: 1.5rem;
}

.burger {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 20px;
  height: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  top: 35px;
  left: 35px;
}
.burger:focus {
  outline: none;
}

.burger div {
  width: 20px;
  height: 2px;
  background: #324bfc;
  border-radius: 5px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

.burger.open div {
  background: white;
}

.burger div:first-child {
  transform: rotate(0);
}
.burger.open div:first-child {
  transform: rotate(45deg);
}

.burger div:nth-child(2) {
  transform: translateX(0);
}
.burger.open div:nth-child(2) {
  background: transparent;
  transform: translateX(20px);
}

.burger div:nth-child(3) {
  transform: rotate(0);
}
.burger.open div:nth-child(3) {
  transform: rotate(-45deg);
}
