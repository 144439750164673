body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

div#root {
  margin: 0;
  
  padding: 0;
  width: 100vw;
  height: 100vh;
}

.gramara-auth {
  background-color: #334cff;
  height: 100vh;
  padding: 120px 1rem 0;
  box-sizing: border-box;
  
  ul.firebaseui-idp-list {
    margin: 0 !important;
  }

  svg.logo {
    display: block;
    margin: 0 auto;
    padding-bottom: 50px;
  }
}

.card {
  background: #fff;
  max-width: 400px;
  padding: 3rem 0;
  margin: 0 auto;
  border-radius: 0.5rem;

  h1 {
    text-align: center;
    margin: 0 0 3rem 0;
  }
}
