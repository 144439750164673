.account {
  .card {
    padding: 2rem 2rem 1rem 2rem;
    max-width: 720px;

    @media (max-width: 599px) {
      padding: 1rem;
    }

    .choices {
      justify-content: center;

      @media (min-width: 768px) {
        margin-top: 3rem;
      }
    }
  }

  .account-settings {
    &.choices {
      margin-top: 2rem;
      justify-content: left;
    }

    .divider {
      margin-top: 1rem;
    }

    button {
      text-transform: none;
      background: #e6ebff;
      border-radius: 8px;
      padding-right: 24px;
      white-space: nowrap;

      span {
        font-size: 14px;
        color: #334cff;
      }
    }
  }

  .settings-grid {
    justify-content: center;
  }

  .switch-label {
    margin-left: 0.5rem;
  }

  .feature-checkmark {
    width: 1rem;
    height: 0.75rem;
    margin-right: 0.4rem;
  }

  .price-heading {
    font-size: 1.3rem;
    font-weight: normal;
    color: #334cff;
    text-align: center;

    &.free {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (min-width: 600px) {
        padding-left: 2.3rem;
        text-align: left;
        justify-content: flex-end;
        align-items: flex-start;
        height: 85%;
      }
    }

    &.pro {
      margin-bottom: 0.8rem;
    }
  }

  .sub-toggle-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0rem;
    padding-bottom: 0px;
  }

  @media (max-width: 599px) {
    .switch-control {
      margin-bottom: 0px;
    }

    .sub-toggle-row {
      margin-bottom: 0;
    }
  }

  .price-section.free {
    padding-bottom: 2rem;

    @media (max-width: 599px) {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    @media (min-width: 600px) {
      padding-left: unset;
      padding-right: 1rem;
      border-right: 2px solid #d9e1ff;
      padding-bottom: 4rem;
    }

    @media (min-width: 768px) {
      padding-right: 2rem;
    }
  }

  .price-section.pro {
    padding: 0 2rem;

    @media (min-width: 600px) {
      padding: 0 1rem;
    }

    @media (min-width: 768px) {
      padding: 0 4rem;
    }

    button {
      border-radius: 11px;
      display: block;
      padding: 0.6rem 2rem;
      line-height: normal;
      margin: 1.5rem auto auto auto;
    }

    .feature {
      color: black;
    }
  }

  .price-section .divider {
    background: #f1f3ff;
    height: 2px;
  }

  .price-heading-section {
    @media (min-width: 600px) {
      height: 120px;
    }
  }

  .price-features {
    padding-left: 0.7rem;
  }

  .feature-section {
    margin: 0.2rem 0;
  }

  .feature {
    font-size: 0.875rem;
    white-space: nowrap;
  }

  .vertical-divider {
    width: 2px;
    background: #d9e1ff;
  }

  .price {
    font-size: 0.875rem;
    color: #000000;
    padding: 0.4rem 0.8rem;
    line-height: normal;
    text-align: center;

    &.discount {
      text-decoration: line-through;
      background: #60fc80;
    }
  }

  .pricing-pro {
    display: flex;
    flex-direction: column;

    .discount {
      margin: auto;
    }
  }

  #braintree-purchase-msg {
    color: red;
    font-size: 0.9rem;
  }
}
