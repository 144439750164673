@font-face {
  font-family: 'MuseoSans';
  src: url('../fonts/MuseoSans_500.otf');
  font-weight: normal;
  font-style: normal;
}

.App {
  display: flex;
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  font-family: MuseoSans;
  font-size: 14px;
  line-height: 27px;
  height: 100%;
}

.editor-area {
  height: calc(100% - 60px);
  min-height: 500px;
  word-spacing: 0.5px;

  @media (min-width: 769px) {
    padding-top: 70px;
  }
}

.editor-area div > span > span > span span[contenteditable='false'] {
  color: #bdc4db;
  opacity: 1 !important;
  vertical-align: baseline !important;
}

button.editor-button {
  border-radius: 8px;
  background-color: #e6ebff;
  color: #334cff;
  box-shadow: none;
  padding: 6px 20px;
  font-family: MuseoSans;
  text-transform: capitalize;
  margin-right: 15px;
}

button.editor-button:hover {
  background-color: #e6ebff;
  color: #334cff;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
}

button.editor-button.active {
  background-color: #334cff;
  color: #e6ebff;
}

button.editor-button > span > img {
  width: 0.7rem;
  margin-right: 8px;
}

button.editor-button > span > img.docs {
  width: 0.85rem;
  margin-right: 8px;
}

div.editor-richtext {
  padding: 5px 0 10px 0;
  margin: 0 !important;
  border: none;
  position: fixed;
  bottom: 0;
  background: #fff;
  left: calc(50% - 450px);
  width: 400px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

div.editor-richtext span {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.editor-richtext span span {
  font-size: 20px;
}

.editor-title {
  color: #000;
  font-size: 21px;
  border: none;
  padding-bottom: 40px;
  padding-left: 0;
  outline: none;
  width: 100%;
  padding-top: 90px;
  height: 30px;
}

.editor-title::placeholder {
  color: #bdc4db;
}

.editor-container {
  width: 640px;
  height: auto;
  padding: 50px;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  box-sizing: border-box;
  align-items: flex-end;
}

.editor {
  width: 400px;
  height: 100%;
}

@media (min-width: 1440px) {
}

@media (min-width: 1140px) and (max-width: 1439px) {
}

@media (min-width: 640px) and (max-width: 1280px) {
  .editor-container {
    width: 50%;
  }
}

@media (min-width: 769px) and (max-width: 1139px) {
  .App {
    font-size: 13px !important;
    line-height: 25px !important;
  }

  button.editor-button {
    font-size: 13px !important;
  }

  div.editor-richtext {
    left: calc(50% - 380px);
    width: 330px;
  }

  div.editor-richtext span {
    width: 40px;
    height: 40px;
    margin-left: 0;
  }

  div.editor-richtext span span {
    font-size: 23px;
  }

  .editor {
    width: 330px;
  }
}

@media (max-width: 768px) {
  .App {
    font-size: 16px !important;
    line-height: 30px !important;
  }

  .editor-container {
    width: 100%;
    padding: 25px 90px 375px 115px;
  }

  .editor {
    width: 100%;
  }

  .editor-area {
    height: 100%;
    min-height: auto;
  }

  div.editor-richtext {
    left: 100px;
    width: 100%;
    top: 0;
    height: 65px;
    padding: 0;
    display: flex;
    align-items: flex-end;
  }

  div.editor-richtext span {
    width: 40px;
    height: 40px;
    margin-left: 0;
    margin-right: 5px;
  }

  div.editor-richtext span span {
    font-size: 23px;
  }
}

@media (min-width: 320px) and (max-width: 576px) {
  .editor-title {
    display: none;
  }

  .editor-container {
    width: 100%;
    padding: 100px 36px 120px 36px;
  }

  .App {
    font-size: 15px !important;
  }

  div.editor-richtext {
    left: 80px;
    height: 60px;
  }

  div.editor-richtext span {
    width: calc((100% - 130px) / 9);
    height: 30px;
    margin-left: 0;
    margin-right: 0;
  }

  div.editor-richtext span span {
    font-size: 23px;
  }
}

.placeholder {
  color: #bdc4db;
}

.annotation-underline {
  border-bottom: 2px solid #334cff;
  color: #334cff;
}

.annotation-test {
  border-bottom: 2px solid black;
  color: white;
  background: black;
}

.inline-divider {
  flex: 1;
  height: 2px;
  background: #b1b9dd;
}
